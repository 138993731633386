import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Result() {
  return (
    <>
    <Breadcumb pagetitle='Result'/>
    <section className='py-5'>
        <div className='container'>
            <div className='mb-5'>
                <h3 class="h4 mb-3">1. Certified Ethical Hacker exam questions set -1</h3>
                <div>
                    <div className='answerList correctAnswer'>
                        <span className='answerIcon text-success'><i class="fa-solid fa-check"></i></span>
                        <span className='answercomment bg-success-subtle px-2 text-xs'>Your answer</span>
                        Teardrop
                    </div>
                    <div className='answerList'>
                        SYN Flood
                    </div>
                    <div className='answerList'>
                        Smurf Attack
                    </div>
                    <div className='answerList'>
                        Ping Of Death
                    </div>
                </div>
            </div>
            <div className='mb-4'>
                <h3 class="h4 mb-3">2. Which of the following is an effective deterrent against TCP session hijacking?</h3>
                <div className='mb-3'>
                    <div className='answerList wrongAnswer'>
                        <span className='answerIcon text-danger'><i class="fa-solid fa-xmark"></i></span>
                        <span className='answercomment bg-success-subtle px-2 text-xs'>Your answer</span>
                        Install And Use An HIDS On The System.
                    </div>
                    <div className='answerList'>
                        Install And Use Tripwire On The System
                    </div>
                    <div className='answerList'>
                        <span className='answercomment bg-success-subtle px-2 text-xs'>Correct answer</span>
                        Enforce Good Password Policy.
                    </div>
                    <div className='answerList'>
                        Use Unpredictable Sequence Numbers.
                    </div>
                </div>
            </div>
            <div className='mb-4'>
                <h3 class="h4 mb-3">3. Which of the following is an effective deterrent against TCP session hijacking?</h3>
                <div className='mb-3'>
                    <div className='answerList wrongAnswer'>
                        <span className='answerIcon text-danger'><i class="fa-solid fa-xmark"></i></span>
                        <span className='answercomment bg-success-subtle px-2 text-xs'>Your answer</span>
                        Install And Use An HIDS On The System.
                    </div>
                    <div className='answerList'>
                        Install And Use Tripwire On The System
                    </div>
                    <div className='answerList'>
                        <span className='answercomment bg-success-subtle px-2 text-xs'>Correct answer</span>
                        Enforce Good Password Policy.
                    </div>
                    <div className='answerList'>
                        Use Unpredictable Sequence Numbers.
                    </div>
                </div>
            </div>
            <div className='mb-4'>
                <h3 class="h4 mb-3">4. Which of the following is an effective deterrent against TCP session hijacking?</h3>
                <div className='mb-3'>
                    <div className='answerList wrongAnswer'>
                        <span className='answerIcon text-danger'><i class="fa-solid fa-xmark"></i></span>
                        <span className='answercomment bg-success-subtle px-2 text-xs'>Your answer</span>
                        Install And Use An HIDS On The System.
                    </div>
                    <div className='answerList'>
                        Install And Use Tripwire On The System
                    </div>
                    <div className='answerList'>
                        <span className='answercomment bg-success-subtle px-2 text-xs'>Correct answer</span>
                        Enforce Good Password Policy.
                    </div>
                    <div className='answerList'>
                        Use Unpredictable Sequence Numbers.
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
