import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Certificate() {
  return (
    <>
    <Breadcumb pagetitle="Certificate"/>
    <section className='py-5'>
        <div className='container'>
            <div className="certificate-container">
                <div className="certificate">
                    <div className="water-mark-overlay"></div>
                    <div className="certificate-header">
                        <img src="assets/images/logo.png" className="logo" alt="" />
                    </div>
                    <div className="certificate-body">
                    
                        <p className="certificate-title text-uppercase"><strong>The Cognize</strong></p>
                        <h1>Certificate of Completion</h1>
                        <p className="student-name">Matthew Taylor</p>
                        <div className="certificate-content">
                            <div className="about-certificate">
                                <p>
                            has completed <span>1</span> hours on topic title here online on Date <span>30th October 2024</span>
                            </p>
                            </div>
                            <p className="topic-title">
                                The Topic consists of 1 Hours Continuity hours and includes the following:
                            </p>
                            <div className="text-center">
                                <p className="topic-description fw-semibold">Android Operating System Mcq </p>
                            </div>
                        </div>
                        <div className="certificate-footer text-muted mt-5">
                            <div className="row">
                                <div className="col-md-6 text-start">
                                    <p>Director: ______________________</p>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        
                                        <div className="col-md-12 text-end">
                                            <p>
                                                Dated by: 30th October 2024
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
