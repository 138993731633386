import React from 'react'
import Breadcumb from '../../Layouts/Breadcumb'


export default function Aboutus() {

  return (
    <>
      <Breadcumb pagetitle="About Us" />

      <section>
        <div className='py-5 text-center'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-10'>
                <h2 className='mb-4 text-capitalize fw-bold'>Welcome to <span className='text-success fw-semibold'>TheCognize</span>, your ultimate online destination for competitive exam and interview preparation.</h2>
                <h3 className='h5'>
                  We are a dedicated community committed to helping you excel in your academic and professional pursuits through a comprehensive suite of resources designed to enhance your preparation journey.
                </h3>
              </div>
            </div>
                
          </div>
        </div>
        <div className='bg-light py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-8'>
                <h3 className='mb-3 text-success fw-semibold'>Our Mission:</h3>
                <p>At TheCognize, our mission is to support and empower learners by providing a diverse array of resources and tools tailored to meet the challenges of competitive exams and interviews. Our platform is designed to deliver high-quality content that is both accessible and effective, ensuring you have everything you need to achieve your goals.</p>
              </div>
              <div className='col-lg-4 text-center'>
                <img src='assets/images/mission.png' className='boxsquareimg' style={{width: '300px'}}/>
              </div>
            </div>
          </div>
        </div>
        <div className='py-5'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-12 order-lg-2'>
                <h3 className='mb-4 text-success fw-bold'>What We Offer:</h3>

                <p className='h5 mt-4'><span className=' fw-semibold'><i class="fa-solid fa-circle-check text-warning"></i> Extensive Question & Answer Database: </span> </p>
                <p>Dive into our vast collection of practice questions and detailed answers covering a wide range of exams and interviews. Whether you are preparing for academic tests, professional certifications, or job interviews, our database is continually updated to reflect current trends and standards.</p>

                <p className='h5 mt-4'><span className=' fw-semibold'><i class="fa-solid fa-circle-check text-warning"></i> Insightful Blogs:</span> </p>
                <p>Explore our collection of blogs that offer valuable advice and strategies for exam and interview preparation. Our blogs cover a variety of topics, from study techniques and time management to stress reduction and career advice, helping you stay informed and motivated.</p>

                <p className='h5 mt-4'><span className='fw-semibold'><i class="fa-solid fa-circle-check text-warning"></i> Practice Multiple-Choice Questions (MCQs):</span> </p>
                <p>Test your knowledge and gauge your readiness with our comprehensive MCQ sets. Our MCQs are designed to mimic the format and difficulty of real exams, allowing you to practice and improve your performance effectively.</p>
                <p className='h5 mt-4'><span className='fw-semibold'><i class="fa-solid fa-circle-check text-warning"></i> Interactive Learning Tools:</span> </p>
                <p>Engage with our interactive tools and resources, including quizzes, flashcards, and mock tests. These tools are designed to make your study sessions more productive and enjoyable, helping you retain information and build confidence.</p>
                <p className='h5 mt-4'><span className='fw-semibold'><i class="fa-solid fa-circle-check text-warning"></i> Personalized Guidance:</span> </p>
                <p>Benefit from tailored advice and support from experts who understand the nuances of various exams and interviews. Our platform offers personalized tips and feedback to help you focus on your strengths and address areas that need improvement.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='py-5 bg-light'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-5'>
                  <img src='assets/images/whychoose.webp'/>
                </div>
              <div className='col-lg-7'>
                
                <h3 className='mb-4 text-success fw-bold'>Why Choose TheCognize? </h3>

                <p><i class="fa-solid fa-thumbs-up text-warning"></i> <span className='fw-semibold'>Comprehensive Coverage:</span> We provide a one-stop solution for all your preparation needs, offering a wide range of resources that cater to different learning styles and requirements.</p>

                <p><i class="fa-solid fa-thumbs-up text-warning"></i> <span className='fw-semibold'>Expert Content:</span> Our content is created by experienced professionals and educators who bring a wealth of knowledge and practical insights, ensuring that you receive accurate and effective preparation materials.</p>

                <p><i class="fa-solid fa-thumbs-up text-warning"></i> <span className='fw-semibold'>User-Friendly Experience:</span> Our intuitive website design makes it easy to navigate and access the resources you need. Whether you’re studying from a computer or mobile device, our platform is designed for convenience and accessibility.</p>


                <p><i class="fa-solid fa-thumbs-up text-warning"></i> <span className='fw-semibold'>Supportive Community:</span> Join a vibrant community of fellow learners who share your goals and aspirations. Our forums and discussion groups offer a space for collaboration, motivation, and mutual support.</p>

                <p>At TheCognize, we are dedicated to your success. Our goal is to provide you with the resources, tools, and support necessary to excel in your exams and interviews. Explore our platform today and take the next step towards achieving your dreams! </p>
              </div>
            </div>
          </div>
        </div>
        

        


     
    </section >  
    </>
  )
}
