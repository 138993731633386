import React,{useState,useEffect} from 'react'
import Breadcumb from '../../Layouts/Breadcumb';
import DashboardNav from './DashboardNav';
import axios from 'axios'
export default function UserBlog() {
  const[title,setTitle]=useState('');
  const[description,setDescription]=useState('');
  const[keyword,setKeyword]=useState('');
  const[image,setImage]=useState('');
  const[error,setError]=useState('');
  const[success,setSuccess]=useState('');
  // session code
  const data = sessionStorage.getItem('userData');
  const userData=JSON.parse(data);

  // end session code
  const validation=()=>{
    if (!title) return "Title is required.";
    if (!description) return "Description is required.";
    if (!keyword) return "Keyword is required.";
    if (!image) return "Image is required.";
    return null; 
};
  async function addBlog()
  {
    const validationError = validation();
    if (validationError) {
        setError(validationError);
        return;
    }
    const formData=new FormData();
    const userId=userData.userId;
    formData.append('title',title);
    formData.append('description',description);
    formData.append('keyword',keyword);
    formData.append('image',image);
    formData.append('userId',userId);
    try{
      const response=await fetch('https://thecognize.com/admin/api/addBlog',{
        method:'POST',
        body:formData,
      });
      const result=await response.json();
    
      if(result.success===true)
      {
        setSuccess("Added blog successfully!");
          setError(null); 
      }
      else{
        if (result.message && result.message.image &&result.message.image.length > 0) {
          setError(result.message.image[0]); 
      }
      else{
        setError('Something wrong.');
      }
      }
    }
    catch(error)
    {
      setError('An unexpected error occurred.');
      setSuccess(null); 
    }
  }

  return (
    <>
    <Breadcumb pagetitle="Add Blog"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3'>
                    <DashboardNav/>
                </div>
                <div className='col-lg-9'>
                  <div className='border rounded overflow-hidden'>
                    <h3 className='h5 px-4 py-3  border-bottom bg-success text-white'>Add Blog</h3>
                    <div className='px-4 py-3'>
                      {error && <h5 className='text-center text-danger'>{error}</h5>}
                      {success && <h5 className='text-center text-success'>{success}</h5>}
                      <form>
                        <div className='row'>
                          <div className='col-lg-7 mb-3'>
                            <label className='mb-1'>Title<span className='text-danger'>*</span></label>
                            <input type='text' className='form-control' autoComplete='off'  onChange={(e)=>setTitle(e.target.value)}/>
                          </div>
                          <div className='col-lg-7 mb-3'>
                            <label className='mb-1'>Description<span className='text-danger'>*</span></label>
                           <textarea  className='form-control' autoComplete='off'  onChange={(e)=>setDescription(e.target.value)}></textarea>
                          </div>
                          <div className='col-lg-7 mb-3'>
                            <label className='mb-1'>Keyword<span className='text-danger'>*</span></label>
                            <input type='text' className='form-control'  autoComplete='off'  onChange={(e)=>setKeyword(e.target.value)}/>
                          </div>
                          <div className='col-lg-7 mb-3'>
                            <label className='mb-1'>Image<span className='text-danger'>*</span></label>
                            <input type='file' className='form-control' accept='image/*' onChange={(e)=>setImage(e.target.files[0])}/>
                          </div>
                          
                          <div className='col-lg-7 mb-3'>
                            <button type='button' className='btn btn-success' onClick={addBlog}>Add Blog</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
