import React ,{useState,useEffect} from 'react';
import Breadcumb from '../../Layouts/Breadcumb'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function Askquestion() {
    const [value, setValue] = useState('');
    const modules = {
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{size: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link', 'image', 'video'],
          ['clean']  
        ]
      };

      const[categoryId,setCategoryId]=useState("");
      const[title,setTitle]=useState("");
      const[description,setDescription]=useState("");
      const[keyword,setKeyword]=useState("");
      const[image,setImage]=useState(null);
      let[error,setError]=useState(null);
      const[success,setSuccess]=useState(null);
      // session code
    const data = sessionStorage.getItem('userData');
    const userData=JSON.parse(data);
  
    // end session code
      const validation=()=>{
          if (!categoryId) return "Category is required.";
          if (!title) return "Title is required.";
          if (!description) return "Description is required.";
          if (!keyword) return "Keyword is required.";
          return null; 
      };
      async function askQuestion(e)
      {
          e.preventDefault(); 
          const validationError = validation();
          if (validationError) {
              setError(validationError);
              return;
          }
          const formData = new FormData();
          const userId=userData.userId;
          formData.append('categoryId', categoryId);
          formData.append('title', title);
          formData.append('description', description);
          formData.append('keyword', keyword);
          formData.append('userId', userId);
          if (image) formData.append('image', image);
          try{
              const response = await fetch('https://thecognize.com/admin/api/addAskQuestion', {
                  method: "POST",
                  // headers: {
                  //     "Content-Type": "application/json",
                  //     "Accept": "application/json",
                  // },
                  body: formData,
              });
         
              const result = await response.json();
             console.warn(result.success);
              if (result.success===true) {
                  setSuccess("Added Question successfully!");
                  setError(null); 
                 // redirectUrl.push('');
              }
              else {
                  if (result.message && result.message.image &&result.message.image.length > 0) {
                      setError(result.message.image[0]); 
                  }
                  else{
                    setError('Something wrong.');
                  }
              }
             
          } catch (error) {
            
              setError(error.message || 'An unexpected error occurred.');
              setSuccess(null); 
          }
      }
      useEffect(() => {
          if (error) {
              const timer = setTimeout(() => {
                  setError(null);
              }, 5000); 
  
              return () => clearTimeout(timer); 
          }
      }, [error]);
  return (
    <>
    <Breadcumb pagetitle="Ask Question"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-lg-7'>
                    <div className='border p-4 rounded shadow-lg customform'>
                    <form onSubmit={askQuestion}>
                            <h2 className='h3 text-center mb-3 pb-4'>Ask Question</h2>
                            {error && <h5 className='text-center text-danger'>{error}</h5>}
                            {success && <h5 className='text-center text-success'>{success}</h5>}
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Category <span className='text-danger'>*</span></label>
                                <select className='form-control form-select' name='categoryId'  required onChange={(e)=>setCategoryId(e.target.value)}>
                                    <option value="">Select Category</option>
                                    <option value="1">Analytics</option>
                                    <option value="2">Company</option>
                                    <option value="3">Language</option>
                                    <option value="4">Management</option>
                                    <option value="5">Programmers</option>
                                    <option value="6">Programs</option>
                                    <option value="7">University</option>
                                    <option value="8">Cyber Security</option>
                                    <option value="9">General</option>
                                </select>
                                <small className='text-muted'> Please choose the appropriate category so others can easily search your question.
                                </small>
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Question Title <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control' name="title" placeholder='Enter Title' required   onChange={(e)=>setTitle(e.target.value)}/>
                                <small className='text-muted'>Please choose an appropriate title for the question to answer it even easier .</small>
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Description <span className='text-danger'>*</span></label>
                                <ReactQuill theme="snow" name="description" required  value={description} onChange={(e)=>setDescription(e.target.value)} />
                            </div>
                            <div className='mb-3'>
                                <label className='mb-2 fw-semibold'>Keyword <span className='text-danger'>*</span></label>
                                <input type='text' className='form-control' name="keyword" placeholder='Enter Keyword' required  value={keyword} onChange={(e)=>setKeyword(e.target.value)}/>
                            </div>
                            <div className='mb-3'>
                            <label className='mb-2 fw-semibold'>Image</label>
                            <input type='file' className='form-control' name="image" accept='image/*' onChange={(e)=>setImage(e.target.files[0])}/>
                            </div>
                            <div className='mb-3 text-center'>
                            <button type="submit" className='btn btn-success rounded-pill px-4'>Publish Your Question</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
