import React from 'react'
import Qalist from './Qalist'
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
export default function Qablock() {
  return (
    <>
        <section className='py-5'>
          <div className='container'>
            <h2 className='h1 fw-bold text-center mb-5'>Recent Question Answer</h2>
            <Qalist/>
            <div className='text-center mt-5'>
              <Link to='/recent-qa' className='btn btn-success rounded-pill px-4'>View All</Link>
            </div>
          </div>
        </section>
    </>
  )
}
