import React, { useState,useEffect } from 'react';
import Breadcumb from '../../Layouts/Breadcumb';
import ReplyQuestion from './ReplyQuestion';
import axios from 'axios';
import {  BrowserRouter as Router,
    Link, useParams } from 'react-router-dom';
export default function Qadetails() {
   
    const [visibleReplyForms, setVisibleReplyForms] = useState([false, false, false]);
    const { slugUrl } = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [answers, setAnswers] = useState([]);
    // Function to toggle visibility of a specific reply form
    const toggleVisibility = (index) => {
        const newVisibleReplyForms = [...visibleReplyForms];
        newVisibleReplyForms[index] = !newVisibleReplyForms[index];
        setVisibleReplyForms(newVisibleReplyForms);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://thecognize.com/admin/api/questionDetail/${slugUrl}`);
               
                // Assuming response.data contains the blog post details directly
                setData(response.data.data);
            } catch (error) {  
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slugUrl]);

    useEffect(() => {
        if (data) {
           
            const fetchAnswers = async () => {
                try {
                    const response = await axios.post('https://thecognize.com/admin/api/replyAnswer', { questionId: data.questionId });
                   
                    if (response.data.success) {
                        setAnswers(response.data.data);
                        setVisibleReplyForms(new Array(response.data.data.length).fill(false)); // Initialize visibility state
                    } else {
                        setError(response.data.message);
                    }
                } catch (err) {
                    setError('An error occurred while fetching answers.');
                    console.error(err);
                }
            };

            fetchAnswers();
        }
    }, [data]);

   
    if (loading) {
        return <p className='text-center'>Loading...</p>;
    }

    if (error) {
        return <p className="text-center">{error}</p>;
    }
  
   
    return (
        <>
            <Breadcumb pagetitle={data.title} />
            <section className='py-5'>
                <div className='container'>
                    <div className='qalistbox mb-4 shadow-lg'>
                        <div className='d-flex'>
                            <div className='qauserimg'>
                                <Link to='/qa-details'><img src='/assets/images/author-11.jpg' alt='author' /></Link>
                            </div>
                            <div className='qapostinfo'>
                                <h3 className='h6 mb-1'><Link to='/qa-details' className='text-dark'>By: Arden Smith</Link></h3>
                                <ul className='mb-3'>
                                    <li>{data.created}</li>
                                    <li>Programmer</li>
                                    <li><i className="fa-light fa-message-lines"></i> 3</li>
                                </ul>
                            </div>
                        </div>
                        <h3 className='qaposttitle mb-3'><Link to='#' className='text-dark'>{data.title}</Link></h3>
                        <p>{data.description}</p>
                       
                        <button type='button' onClick={() => toggleVisibility(0)} className='border-0 bg-transparent text-success fw-semibold'>
                            <i className="fa-light fa-reply"></i> Reply
                        </button>
                        {visibleReplyForms[0] && (
                            <div className='mt-2'>
                                <ReplyQuestion />
                            </div>
                        )}

                        <div className='replyPost mt-4 ps-3'>
                            {answers.map((answer, index)=>(
                            
                            <div className='bg-light rounded p-3 mb-2' key={index}>
                                <div className='d-flex'>
                                    <div className='qauserimg'>
                                        <Link to='/qa-details'><img src='/assets/images/author-11.jpg' alt='author' /></Link>
                                    </div>
                                    <div className='qapostinfo'>
                                        <h3 className='h6 mb-1'><Link to='/qa-details' className='text-dark'>By: {answer.name}</Link></h3>
                                        <ul className='mb-3'>
                                            <li>{answer.created}</li>
                                            <li>Programmer</li>
                                            <li><i className="fa-light fa-message-lines"></i> 3</li>
                                        </ul>
                                    </div>
                                </div>
                                <h3 className='qaposttitle mb-3 h5'><a href='#' className='text-dark'>{answer.title}</a></h3>
                                <p>{answer.description}</p>
                                <button type='button' onClick={() => toggleVisibility(index+1)} className='border-0 bg-transparent text-success fw-semibold'>
                                    <i className="fa-light fa-reply"></i> Reply
                                </button>
                                {visibleReplyForms[index+1] && (
                                    <div className='mt-2'>
                                        <ReplyQuestion />
                                    </div>
                                )}
                            </div>

                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
