import React from 'react'
import {Link} from 'react-router-dom'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Mcqresult() {
  return (
    <>
    <Breadcumb pagetitle="Result"/>
    <section className='py-5'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-lg-6'>
                    <div className='p-4 shadow-lg rounded-lg'>
                        <h3 className='text-center mb-4 h4'>Result</h3>
                        <table className='table table-borderedtable table-dark table-striped'>
                            <tbody>
                                <tr>
                                    <td>Total Question: </td>
                                    <td className='fw-semibold'>10</td>
                                </tr>
                                <tr>
                                    <td>Attempt Question </td>
                                    <td className='fw-semibold'>10</td>
                                </tr>
                                <tr>
                                    <td>Correct Answer: </td>
                                    <td className='fw-semibold'>8</td>
                                </tr>
                                <tr>
                                    <td>Wrong Answer: </td>
                                    <td className='fw-semibold'>2</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='text-center mt-3 d-flex gap-2 justify-content-center'>
                            <Link to="/result-answer" className="btn btn-success">View Your Result</Link>
                            <Link to="/certificate" className="btn btn-warning">View Certificate</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
