import React, { useEffect, useState } from 'react';
import Breadcumb from '../../Layouts/Breadcumb';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Macqlist() {
    const { mcqQuestionId } = useParams();
    const [mcqList, setmcqList] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        mcqTestId: '',
        questionId: [],
        correctAnswer: [],
        type: [],
        answer: {}
    });
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const fetchMcqList = async () => {
            try {
                const response = await axios.get(`https://thecognize.com/admin/api/mcqQuestionList/${mcqQuestionId}`);
                setmcqList(response.data.data);
                setData(response.data.mcqQuestionList);
            } catch (error) {  
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchMcqList();
    }, [mcqQuestionId]);

    const checkValidation = () => {
        if (Object.values(formData.answer).every(answer => answer.length === 0)) {
            alert('Please check at least one option');
            return false;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        
        if (type === 'radio') {
            setFormData(prevData => ({
                ...prevData,
                answer: {
                    ...prevData.answer,
                    [name]: value
                }
            }));
        } else if (type === 'checkbox') {
            setFormData(prevData => {
                const newAnswers = { ...prevData.answer };
                const [questionIndex] = name.split('[')[1].split(']');
                if (!newAnswers[questionIndex]) {
                    newAnswers[questionIndex] = [];
                }
                if (checked) {
                    newAnswers[questionIndex].push(value);
                } else {
                    newAnswers[questionIndex] = newAnswers[questionIndex].filter(v => v !== value);
                }
                return { ...prevData, answer: newAnswers };
            });
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (!checkValidation()) {
        //     return;
        // }
        try {
            const response = await axios.post('https://thecognize.com/admin/api/mcqAnswer', formData);
            console.log(response); 
            setSuccess('Question submit successful!');
            setError(null);
        } catch (err) {
            console.error(err); 
            setError('An error occurred while submitting the form.');
            setSuccess(null);
        }
    };
   
    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="text-center">{error}</p>;
    }

    return (
        <>
            <Breadcumb pagetitle={`${mcqList.title}`} />
            <section className='py-5'>
                <div className='container'>
                    <form action="/result">
                        <h3 className='h4 mb-4 border-bottom pb-3 border-light-subtle text-success'>{mcqList.title}</h3>
                        <input type="hidden" name="mcqTestId" value={mcqList.mcqId} />
                        {data.length > 0 ? (
                            data.map((item, index) => {
                                let questionContent;
                                if (item.type === 'radio') {
                                    questionContent = (
                                        <>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${index}]`} value="A" onChange={handleChange} /> {item.A}</label></div>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${index}]`} value="B" onChange={handleChange}/> {item.B}</label></div>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${index}]`} value="C" onChange={handleChange}/> {item.C}</label></div>
                                            <div className='mb-2 quebox'><label><input type='radio' name={`answer[${index}]`} value="D" onChange={handleChange}/> {item.D}</label></div>
                                        </>
                                    );
                                } else if (item.type === 'checkbox') {
                                    questionContent = (
                                        <>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`check_answer[${index}]`} value="A" onChange={handleChange}/> {item.A}</label></div>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`check_answer[${index}]`} value="B" onChange={handleChange}/> {item.B}</label></div>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`check_answer[${index}]`} value="C" onChange={handleChange}/> {item.C}</label></div>
                                            <div className='mb-2 quebox'><label><input type='checkbox' name={`check_answer[${index}]`} value="D" onChange={handleChange}/> {item.D}</label></div>
                                        </>
                                    );
                                }

                                return (
                                    <div className='questionList border border-light rounded p-3 mb-3' key={item.id}>
                                        <h4 className='h5 mb-3'>{index + 1}) {item.question}</h4>
                                        
                                        <input type="hidden" name={`questionId[${index}]`} value={item.id} />
                                        <input type="hidden" name={`correctAnswer[${index}]`} value={item.answer} />
                                        <input type="hidden" name={`type[${index}]`} value={item.type} />
                                        {questionContent}
                                    </div>
                                );
                            })
                        ) : (
                            <div className='questionList border border-light rounded p-3 mb-3'>
                                <h4 className='h5 mb-3 text-center'>No Data Found</h4>
                            </div>
                        )}
                        {/* <div className='text-center mt-4'>
                        <button className='btn btn-warning px-4 m-1'>Save</button>
                        <button className='btn btn-success px-4 m-1' type='submit'>Submit</button>
                        </div> */}
                    </form>
                </div>
            </section>
        </>
    );
}
