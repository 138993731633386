import React, { useEffect, useState } from 'react';
import {BrowserRouter as Router,  Link } from "react-router-dom";
import axios from 'axios';
export default function Blogsidebar() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('https://thecognize.com/admin/api/blogList')
            .then((response) => {
                if (response.data.success) {
                    setData(response.data.data);
                } else {
                    setError(response.data.message);
                }
                setLoading(false);
            })
            .catch((error) => {
              
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data 456');
                setLoading(false);
            });
    }, []); 
   
  return (
    <>
    <div className='p-4 border rounded-4'>
        <h2 className='mb-4 h4 border-bottom pb-3'>Recent Blog</h2>
        { data.length > 0 ? (
        data.slice(0,5).map((item,index)=>(
        <div className='d-flex gap-3 mb-3'>
            <div>
                <Link to={`/blog-details/${item.userId}/${item.slugUrl}`}><img src='/assets/images/s2.jpg' className='listBlogimg rounded'/></Link>
            </div>
            <div>
                <h3 className='h5 mb-1'><Link to={`/blog-details/${item.userId}/${item.slugUrl}`} className="text-dark">{item.title}</Link></h3>
                <p className='text-muted'><small><i class="far fa-calendar-alt"></i> {item.created}</small></p>
            </div>
        </div>
        ))
        ) :(
            <div className='d-flex gap-3 mb-3'>
            <div>
                <h3 className='h5 mb-1'>No Data Found</h3>
              
            </div>
            </div>
         ) }
    
        {/* <div className='d-flex gap-3 mb-3'>
            <div>
                <Link to="#"><img src='/assets/images/s3.jpg' className='listBlogimg rounded'/></Link>
            </div>
            <div>
                <h3 className='h5 mb-1'><Link to="#" className="text-dark">Top best hacking movie to watch</Link></h3>
                <p className='text-muted'><small><i class="far fa-calendar-alt"></i> Oct 22,2025</small></p>
            </div>
        </div>
        <div className='d-flex gap-3 mb-3'>
            <div>
                <Link to="#"><img src='/assets/images/s1.jpg' className='listBlogimg rounded'/></Link>
            </div>
            <div>
                <h3 className='h5 mb-1'><Link to="#" className="text-dark">Top best hacking movie to watch</Link></h3>
                <p className='text-muted'><small><i class="far fa-calendar-alt"></i> Oct 22,2025</small></p>
            </div>
        </div>
        <div className='d-flex gap-3 mb-3'>
            <div>
                <Link to="#"><img src='/assets/images/s4.jpg' className='listBlogimg rounded'/></Link>
            </div>
            <div>
                <h3 className='h5 mb-1'><Link to="#" className="text-dark">Top best hacking movie to watch</Link></h3>
                <p className='text-muted'><small><i class="far fa-calendar-alt"></i> Oct 22,2025</small></p>
            </div>
        </div>
        <div className='d-flex gap-3 mb-3'>
            <div>
                <Link to="#"><img src='/assets/images/s2.jpg' className='listBlogimg rounded'/></Link>
            </div>
            <div>
                <h3 className='h5 mb-1'><Link to="#" className="text-dark">Top best hacking movie to watch</Link></h3>
                <p className='text-muted'><small><i class="far fa-calendar-alt"></i> Oct 22,2025</small></p>
            </div>
        </div>
        <div className='d-flex gap-3 mb-3'>
            <div>
                <Link to="#"><img src='/assets/images/s2.jpg' className='listBlogimg rounded'/></Link>
            </div>
            <div>
                <h3 className='h5 mb-1'><Link to="#" className="text-dark">Top best hacking movie to watch</Link></h3>
                <p className='text-muted'><small><i class="far fa-calendar-alt"></i> Oct 22,2025</small></p>
            </div>
        </div> */}
    </div>
    </>
  )
}
