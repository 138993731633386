import React, {useState} from 'react';

import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
import axios from 'axios';
import {useHistory} from 'react-router-dom';

export default function Login() {
   const[email,setEmail]=useState('');
   const[password,setPassword]=useState('');
   let[error,setError]=useState(null);
   const[success,setSuccess]=useState(null);
    const redirectUrl=useHistory();
    const validation=()=>{
        if (!email) return "Email is required.";
        if (!password) return "Password is required.";
        return null; 
    };
   async function login()
   {
    const validationError = validation();
    if (validationError) {
        setError(validationError);
        return;
    }
      const loginData={email,password};
      try{
          
        const response = await fetch('https://thecognize.com/admin/api/login', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(loginData),
        });

          const result= await response.json();
            console.log(result.success);
          if (result.success===true) {
            setSuccess("Login successful!");
            setError(null); 
            const userData={
                userId:result.data.userId,
                name:result.data.name,
                email:result.data.email,
            };
           
            sessionStorage .setItem('userData',JSON.stringify(userData));
            window.location.href = '/dashboard';
           // redirectUrl.push('/dashboard');
        }
        else{
           
          setError(result.message);
        }
          
      }
      catch(error){
        setError(error.message || "An unexpected error occured");
        setSuccess(null);
      }
   }
  return (
    <>
    <section className='logscreen'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-6'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-9 col-md-6'>
                            <form className="p-4">
                                <h3 className="mb-3 fw-bold text-center">SIGN IN</h3>
                                <p className='text-center mb-4'>Welcome back! Login to access the The Cognize</p>
                                {error && <h5 className='text-center text-danger'>{error}</h5> }
                                {success && <h5 className='text-center text-success'>{success}</h5> }
                                <div className="mb-3">
                                    <input type="email" className="form-control" id="exampleInputEmail"   placeholder='Email' value={email} onChange={(e)=>setEmail(e.target.value)} autoComplete='off'/>
                                </div>
                                <div className="mb-3">
                                    <input type="password" className="form-control" id="exampleInputPassword" 
                                     placeholder='Password' value={password} onChange={(e)=>setPassword(e.target.value)} autoComplete='off'/>
                                </div>
                                
                                <button type="button" className="btn btn-warning w-100 fw-semibold text-uppercase" onClick={login}>Continue</button>
                                <p className='mt-3 mb-2 text-center'><a href='javascript:void(0)' className='text-dark'> Forgot your password?</a></p>
                                <p className='mt-2 text-center'>Don't have account? <Link to='/register' className='text-success'>Create an account</Link></p>
                            </form>
                        </div>
                    </div>
                          
                </div> 
            </div>
        </div>
    </section>    
    </>
  )
}
