import React,{useState,useEffect} from 'react'
import Breadcumb from '../../Layouts/Breadcumb'

export default function Contact() {
  const[name,setName]=useState('');
  const[email,setEmail]=useState('');
  const[phone,setPhone]=useState('');
  const[subject,setSubject]=useState('');
  const[message,setMessage]=useState(null);
  const[error,setError]=useState(null);
  const[success,setSuccess]=useState(null);
  
  function onlyAlphabets(event) {
    const x = event.which || event.keyCode;
    if (!((x >= 65 && x <= 90) || (x >= 97 && x <= 122) || x === 32)) {
      event.preventDefault();
    }
  }
  function onlyNumber(event)
    {
       const x = event.which || event.keyCode;
        if((x >= 48 ) && (x <= 57 ) || x == 8 | x == 9 || x == 13 )
        {
            return;
        }else{
            event.preventDefault();
        }
    }

    const validation=()=>{
      if(!name) return "Name is required";
      if(!email) return "Email is required";
      if(!phone) return "Phone is required";
      if(!subject) return "Subject is required";
      return null;
    };

    async function sendContact()
    {
      const validationError=validation();
      if (validationError) {
        setError(validationError);
        return;
    }
    const contactData={name,email,phone,subject,message};
    try{
      const response=await fetch('https://thecognize.com/admin/api/saveContact',{
        method :"POST",
        headers :{
          'content-Type':'application/json',
          'Accept':'application/json',
        },
        body : JSON.stringify(contactData),
      });
      const result=await response.json();
      if(result.success===true)
      {
        setSuccess('Send Contact Details');
        setError(null);
        setName(null);
        setEmail(null);
        setPhone(null);
        setSubject(null);
        setMessage(null);
       
      }
      else{
        if (result.message && result.message.email &&result.message.email.length > 0) {
          setError(result.message.email[0]); 
      }
      else if(result.message && result.message.phone &&result.message.phone.length > 0)
      {
          setError(result.message.phone[0]);
      }
      }
    }
    catch(error)
    {
      setError(error.message || 'An unexpected error occurred.');
      setSuccess(null);
    }

    }
  return (
    <>
        <Breadcumb pagetitle="Contact Us"/>
        <section className='py-5'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-lg-6'>
                <form className="p-0 contactForm">
                  <h3 className="mb-3 fw-bold">Send Us A Message</h3>
                  {error && <h5 className="text-center text-danger">{error}</h5>}
                  {success && <h5 className="text-center text-success">{success}</h5>}
                  <div className='row'>
                    <div className="mb-3 col-lg-6">
                        <label>Name<span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" id="exampleInputName"  placeholder='User Name'  onKeyPress={onlyAlphabets} onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div className="mb-3 col-lg-6">
                        <label>Email<span className='text-danger'>*</span></label>
                        <input type="email" className="form-control" id="exampleInputEmail"  placeholder='Email Address' onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div className="mb-3 col-lg-6">
                        <label>Contact Number<span className='text-danger'>*</span></label>
                        <input type='tel' className='form-control' placeholder='Contact Number' maxLength={10}  onKeyPress={onlyNumber} onChange={(e)=>setPhone(e.target.value)}/>
                    </div>
                    <div className="mb-3 col-lg-6">
                        <label>Subject<span className='text-danger'>*</span></label>
                        <input type='text' className='form-control' placeholder='Subject'  onChange={(e)=>setSubject(e.target.value)}/>
                    </div>
                    <div className="mb-3 col-lg-12">
                        <label>Message</label>
                        <textarea className='form-control' placeholder='Message' rows="7"  onChange={(e)=>setMessage(e.target.value)}></textarea>
                    </div>
                  </div>
                  <button type="button" className="btn btn-warning w-100 fw-semibold text-uppercase" onClick={sendContact}>Submit</button>
                </form>
              </div>
              <div className='col-lg-4 mt-5'>
                <div className='mb-4'>
                  <h4 className='h5 text-success'><i class="fa-regular fa-location-dot"></i>  Address</h4>
                  <p>New Delhi</p>
                </div>
                <div className='mb-4'>
                  <h4 className='h5 text-success'><i class="fa-regular fa-envelopes"></i>  Email Address</h4>
                  <p> info@thecognize.com</p>
                </div>
               
              </div>
            </div>
          </div>
        </section>
    </>
  )
}
