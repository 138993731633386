import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import axios from 'axios';

export default function Questioset() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('https://thecognize.com/admin/api/mcqQuestionTopicList')
            .then((response) => {
                if (response.data.success) {
                    setData(response.data.data);
                } else {
                    setError(response.data.message);
                }
                setLoading(false);
            })
            .catch((error) => {
               
                console.error('Error fetching data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch data');
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p className="text-center">{error}</p>;
    }

    return (
        <>
            {data.length > 0 ? (
                data.map((item, index) => (
                    <Link to={`/mcq-list/${item.slugUrl}`} className='questioset mb-2' key={index}>
                        <div className='d-flex justify-content-between'>
                            <div>{item.title}</div>
                            <div>
                                <span className='quMore'><i className="fa-sharp fa-regular fa-arrow-right"></i></span>
                            </div>
                        </div>
                    </Link>
                ))
            ) : (
                <a href="javascript:void(0)" className='questioset mb-2'>
                    <div className='d-flex justify-content-between'>
                        <div>No Data Found</div>
                        <div>
                            <span className='quMore'><i className="fa-sharp fa-regular fa-arrow-right"></i></span>
                        </div>
                    </div>
                </a>
            )}
        </>
    );
}
